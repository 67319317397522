0
<template>
  <div>
    <div class="main-title">礼物赠送配置</div>
    <div class="content-list-page page-content">
      <div class="padding-t-sm padding-b-sm">
        <span class="margin-l-sm fl-l">
          <el-input
            v-model="searchData.name"
            placeholder="名称"
            size="mini"
          ></el-input>
        </span>
        <span class="margin-l-sm fl-l">
          <el-button
            icon="el-icon-search"
            size="small"
            type="primary"
            @click="search"
            >搜索</el-button
          >
        </span>

        <span class="fl-r">
          <router-link class="router-link" to="/giftSet/add">
            <el-button size="small" type="primary">新增</el-button>
          </router-link>
        </span>

        <div style="clear: both"></div>
      </div>

      <div class="table-content">
        <el-table
          ref="singleTable"
          v-loading="loading"
          :data="tableData"
          :header-cell-style="{ padding: 0 }"
          highlight-current-row
          style="width: 100%; background: #fff"
        >
          <el-table-column label="ID" property="id" width="80">
          </el-table-column>
          <el-table-column label="累计天数">
            <template slot-scope="scope">
              {{ scope.row.start_sign_day + "-" + scope.row.end_sign_day }}
            </template>
          </el-table-column>
          <el-table-column label="赠送礼品" property="name"></el-table-column>

          <el-table-column label="学币" property="credit"></el-table-column>

          <el-table-column
            label="创建时间"
            property="created_at"
          ></el-table-column>
          <el-table-column
            label="更新时间"
            property="updated_at"
          ></el-table-column>
          <el-table-column label="操作" property="address">
            <template slot-scope="scope">
              <span>
                <el-link
                  slot="reference"
                  type="primary"
                  @click="editDetail(scope.row)"
                  >编辑</el-link
                >
              </span>
              -
              <span>
                <el-popconfirm
                  icon="el-icon-info"
                  icon-color="red"
                  title="删除后将不可恢复，确定删除吗？"
                  @confirm="deleted(scope.row)"
                >
                  <el-link slot="reference" type="primary">删除</el-link>
                </el-popconfirm>
              </span>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="table-batch">
        <span class="fl-r">
          <el-pagination
            :current-page="page"
            :page-size="pageSize"
            :page-sizes="[10, 20]"
            :total="total"
            background
            layout="total, sizes, prev, pager, next, jumper"
            small
            @size-change="setPageSize"
            @current-change="setPage"
          >
          </el-pagination>
        </span>
        <div style="clear: both"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "list",
  data() {
    return {
      page: 1,
      pageSize: 10,
      total: 0,
      loading: true,
      imageUrl: config.imageUrl,
      searchData: {},

      tableData: [],
    };
  },
  methods: {
    ...mapActions("rule", ["ruleList", "delRule"]),

    search() {
      this.tableData = [];
      this.page = 1;
      this.getList();
    },
    async alterStatus(row) {
      try {
        row.disabled = true;
        const { res_info } = await this.setGiftStatus(row);
        if (res_info !== "ok") return;
        this.$message.success("设置成功");
        this.getList();
      } finally {
        row.disabled = false;
      }
    },
    async deleted(row, rowIndex) {
      const { res_info } = await this.delRule(row.id);
      if (res_info !== "ok") return;
      this.$message.success("删除成功");
      this.getList();
    },
    editDetail(row) {
      this.$router.push("/giftSet/edit/" + row.id);
    },
    statusSet(row, rowIndex) {
      let status = row.status == 1 ? 0 : 1;
      let data = {
        id: row.id,
        status: status,
        model: "Poster\\TemplateModel",
      };
      this.setStatus(data).then((res) => {
        if (res.ret === 0) {
          this.$message.success("设置成功");
          this.getMomentData();
          this.getList();
        }
      });
    },
    getList() {
      this.loading = true;
      let data = {
        page: this.page,
        pageSize: this.pageSize,
        ...this.searchData,
      };
      this.ruleList(data).then((res) => {
        this.loading = false;
        this.tableData = res.data.list;
        this.total = res.data.total;
      });
    },
    setPageSize(pageSize) {
      this.pageSize = pageSize;
      this.getList();
    },
    setPage(page) {
      this.page = page;
      this.getList();
    },
    typeName(type) {},
  },
  mounted() {
    this.getList();
  },
};
</script>

<style scoped></style>